import { type DashboardConfig } from '#types/index.js'

export const dashboardConfig: DashboardConfig = {
	mainNav: [
		{
			title: 'PCDH19',
			href: '/posts/causas-del-PCDH19',
			description: 'Información sobre la mutación y cómo afrontarla.',
		},
		{
			title: 'Asociación',
			href: '/asociacion',
			description: 'Conoce qué hacemos para ayudar a los afectados.',
		},
		{
			title: 'Cómo ayudar',
			href: '/como-ayudar',
			description: 'Apoya a los pacientes de esta enfermedad rara.',
		},
	],
	sidebarNav: [
		{
			title: 'Prediagnóstico',
			href: '/posts/realizar-el-test-genetico',
		},
		{
			title: 'PCDH19',
			href: '/posts/causas-del-PCDH19',
		},
		{
			title: 'Vivir con PCDH19',
			href: '/posts/protocolos-de-emergencias',
		},
		{
			title: 'Experiencias personales',
			href: '/posts/casos',
		},
	],
}
